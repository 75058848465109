<template>
	<read-template module="SUPPLIER" />
</template>
<script>
import ReadTemplate from '@/components/agreement/readDocument/ReadDocument.vue';

export default {
	name: 'PrecontractTemplateView',
	components: { ReadTemplate },
};
</script>
